import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useCurrenciesList() {
  const toast = useToast();
  const refCurrenciesTable = ref(null);

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(10);
  const searchQuery = ref("");
  const totalCurrencies = ref(0);
  const currentPage = ref(1);
  const isAddNewSidebarVisible = ref(false);
  const isLoading = ref(false);

  const currencyStatuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const symbolDirections = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
  ];

  const updateCurrency = async (currencyId, field, value) => {
    try {
      const formData = new FormData();
      formData.append("field", field);
      formData.append("value", value.value ? value.value : value);
      const response = axios.patch(`/currencies/${currencyId}`, formData);
    } catch (error) {}
  };

  const fetchCurrencies = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", searchQuery.value);
      formData.append("page", currentPage.value);
      formData.append("perPage", perPage.value);

      const response = await axios.post("currencies/search", formData);
      const { data } = response;
      totalCurrencies.value = data.count;
      return data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            error.response.status === 401
              ? "you dont have permissions to do this action"
              : "Error fetching currencies list",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const addCurrency = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("shortName", data.shortName);
      formData.append("symbol", data.symbol);
      formData.append("symbolDirection", data.symbolDirection);
      formData.append("rate", data.rate);
      formData.append("isActive", data.isActive);
      const response = await axios.post("currencies", formData);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new currency",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const tableColumns = [
    { key: "name", sortable: false },
    { key: "shortName", sortable: false },
    { key: "symbol", sortable: false },
    { key: "symbolDirection", sortable: false },
    { key: "rate", sortable: false },
    { key: "isActive", sortable: false, label: "Status" },
    { key: "actions" },
  ];

  const resolveCurrencyStatusVariant = (status) => {
    if (status == 1) return "success";
    return "danger";
  };

  const dataMeta = computed(() => {
    const localItemsCount = refCurrenciesTable.value
      ? refCurrenciesTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCurrencies.value,
    };
  });

  const refetchData = () => {
    refCurrenciesTable.value.refresh();
  };

  // let's watch if there is something changed in the currency
  watch([perPage, searchQuery, currentPage], () => {
    refetchData();
  });

  return {
    perPage,
    perPageOptions,
    searchQuery,
    fetchCurrencies,
    tableColumns,
    resolveCurrencyStatusVariant,
    refCurrenciesTable,
    totalCurrencies,
    currentPage,
    dataMeta,
    isAddNewSidebarVisible,
    currencyStatuses,
    addCurrency,
    symbolDirections,
    refetchData,
    updateCurrency,
    isLoading,
  };
}
